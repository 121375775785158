@import './variables.scss';

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #858585 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #858585;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }

html, body
{
    overflow: hidden;
}

body {
    
}

b, thead th
{
    font-weight: 600;
}

h1, h2, h3
{
    margin-bottom: 20px;
}

textarea
{
    resize: none;
}

.badge
{
    font-weight: 600;
}

.badge.bg-white,
.badge.bg-light
{
    color: #000000;
}

.btn
{
    transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.btn-block
{
    display: block;
    width: 100%;
}

.btn-block + .btn-block
{
    margin-top: 0.5rem;
}

.btn i
{
    opacity: 0.8;
}

.btn i:first-child,
.badge i:first-child
{
    margin-right: 0.5em;
}

.btn i:last-child,
.badge i:last-child
{
    margin-left: 0.5em;
}

.btn i:first-child:last-child,
.badge i:first-child:last-child
{
    margin-left: 0;
    margin-right: 0;
    opacity: 1;
}

a,
.btn-link
{
    text-decoration: none;
}

a:hover,
.btn-link:hover
{
    text-decoration: underline;
}

.btn-white
{
    border-color: rgba(0,0,0,0) !important;
}

.btn-white:hover
{
    background-color: rgba(var(--bs-dark-rgb), 0.1);
}

.btn-white-selected:hover {
    background-color: transparent !important;
}

.nav-link {
    color: inherit;
    background-color: transparent;
}

.nav-link.active {
    background-color: white;
    color: var(--bs-primary) !important;
    text-shadow: 0 0 1px rgba(var(--bs-primary-rgb), 0.25) !important;
}

.list-group-item.active,
.list-group-item:active,
.dropdown-item.active,
.dropdown-item:active,
.btn-white-selected,
.dropdown.show .dropdown-toggle.btn-white {
    background-color: transparent !important;
    color: var(--bs-primary) !important;
    text-shadow: 0 0 1px rgba(var(--bs-primary-rgb), 0.25) !important;
}

.list-group-item.active,
.list-group-item:active {
    border-color: var(--bs-list-group-border-color) !important;
}

.alert > i:first-child
{
    margin-right: 8px;
}

.dropdown-item i:first-child
{
    width: 1.25em;
    text-align: center;
    margin-right: 0.75em;
}

.progress
{
    background-color: $color_border;
}

.progress-thin
{
    height: 0.5rem;
}

.progress-bar
{
    transition: background-color 0.2s ease-out, opacity 0.2s ease-out, width 0.2s ease-out;
}

.modal-dialog
{
    margin: 1.75rem auto !important;
}

.mce-tinymce 
{
    transition: border-color ease-in-out 0.2s, box-shadow ease-in-out 0.2s
}

.ng-invalid.form-control,
.ng-invalid.input-group,
.ng-invalid > .TinyMCECont .mce-tinymce 
{
    box-shadow: 0 0 1px 0 $danger;
    border-color: $danger;
}

.form-control.focus
{
    border: 1px solid $input-focus-border-color;
    box-shadow: $input-box-shadow, $input-btn-focus-box-shadow;
}

.form-group
{
    margin-bottom: 1rem;
}

.form-group label
{
    margin-bottom: 0.5rem;
}

.PgCenterImage
{
    background-repeat: no-repeat;
    background-position: center;
}

.ngb-dp-weekday
{
    font-size: 0;
    overflow: hidden;
}

.ngb-dp-weekday::first-letter
{
    font-size: 0.875rem;
}

/* GOOGLE MAPS */

.pac-container
{
    z-index: 1100;
}

/* DRAG & DROP */

/* Draggable*/

.drag-border {
    opacity: 0.5;
}

.drag-handle {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.drag-handle:active { 
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

/* Droppable */

.drag-hint-border {
    background-color: shift-color($primary, -60%);
}

.drag-over-border {
    background-color: shift-color($primary, -40%);
}

/*.drag-transit {}*/

/* CUSTOM */

.PGWeatherDisplay
{
    width: 1.25em;
    height: 1.25em;
    position: relative;
    text-align: left;
}

.PGWeatherDisplay i
{
    width: 100%;
    height: 100%;
    line-height: 1.25em;
    text-align: center;
    position: absolute;
    margin: 0 !important;
    opacity: 1;
}

.PGHighlight--Warning
{
    animation: PGHighlight-Warning-Anim 1.4s ease;
}

.PGHighlight--WarningStatic
{
    background-color: shift-color($warning, -80%); box-shadow: 0 0 0 1px shift-color($warning, -70%) inset;
}

@keyframes PGHighlight-Warning-Anim
{
    0% { background-color: transparent; box-shadow: 0 0 0 1px transparent inset; }
    25% { background-color: shift-color($warning, -80%); box-shadow: 0 0 0 1px shift-color($warning, -70%) inset; }
    75% { background-color: shift-color($warning, -80%); box-shadow: 0 0 0 1px shift-color($warning, -70%) inset; }
    100% { background-color: transparent; box-shadow: 0 0 0 1px transparent inset; }
}

.PGHighlight--Danger
{
    animation: PGHighlight-Danger-Anim 1.4s ease;
}

.PGHighlight--DangerStatic
{
    background-color: shift-color($danger, -80%); box-shadow: 0 0 0 1px shift-color($danger, -70%) inset;
}

@keyframes PGHighlight-Danger-Anim
{
    0% { background-color: transparent; box-shadow: 0 0 0 1px transparent inset; }
    25% { background-color: shift-color($danger, -80%); box-shadow: 0 0 0 1px shift-color($danger, -70%) inset; }
    75% { background-color: shift-color($danger, -80%); box-shadow: 0 0 0 1px shift-color($danger, -70%) inset; }
    100% { background-color: transparent; box-shadow: 0 0 0 1px transparent inset; }
}

.PGPicker
{
    border: 1px solid $color_border;
}

.PGTooltip
{
    position: relative;
}

.PGTooltip:hover > .PGTooltip-Content
{
    display: block;
}

.PGTooltip-Content
{
    position: absolute;
    z-index: 100;
    width: max-content;
    padding: 5px 10px;
    display: none;
    border-radius: 0.375rem;
    background-color: $color_disabled_background;
    border: 1px solid $color_border;
    animation: PGTooltip-Show-Anim 0.2s ease-out;
    bottom: calc(100% + 0.5em);
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 400px;
    font-size: 0.8rem;
    text-align: left;
    white-space: normal;
}

@keyframes PGTooltip-Show-Anim
{
    0% { opacity: 0; transform: translate(-50%, -5px); }
    100% { opacity: 1; transform: translate(-50%, 0); }
}

.PGTooltip-Content::before
{
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    transform: rotate(-45deg);
    z-index: 10;
    border-left: 1px solid $color_border;
    border-bottom: 1px solid $color_border;
    background-color: $color_disabled_background;
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    margin-bottom: -5px;
}

.PGTooltip-Content ol, 
.PGTooltip-Content ul, 
.PGTooltip-Content dl
{
    padding: 0 0 0 1.5em;
    margin: 0;
}

.PGTooltip-Content p
{
    margin: 0
}

.PGButtonIcon
{
    padding: 0; 
    width: 1.5em;   
    height: 1.5em;
    text-align: center;
    line-height: 1em;
}

.PGButtonBar
{
    display: inline-block;
}

.PGButtonBar .btn
{
    text-transform: uppercase;
    border-radius: 0;
    margin: 0;
}

.PGButtonBar .btn:first-child
{
    border-radius: 0.375rem 0 0 0.375rem;
}

.PGButtonBar .btn:last-child
{
    border-radius: 0 0.375rem 0.375rem 0;
}

.PGButtonBar .btn:first-child:last-child
{
    border-radius: 0.375rem;
}

.PGResourcePickModal .modal-content
{
    height: calc(100vh - 56px);
    overflow: hidden;
}

.PGResourcePickModal .modal-body
{
    position: relative;
    overflow: hidden;
}

.PGResourcePickModal app-pg-resource-embed
{
    height: 100%;
    overflow: hidden;
    display: block;
}

.PGFileFormat
{
    display: flex;
    flex-wrap: wrap;
}

.PGFileFormat > div
{
}

.PGFileFormat > div > img,
.PGFileFormat > div > video
{
    object-fit: cover;
    display: block;
    height: 4.5rem;
    width: 8rem;
}

.PGLanguageBadge
{
    width: 2em;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
}

.PGFormatHTML > *:last-child
{
    margin-bottom: 0;
}

.PGFormatTable td
{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

#PGMainLoading
{
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: -1;
}

.PGMultiSpin
{
    display: flex;
    justify-content: center;
    align-items: center;
}

.PGMultiSpin div
{
    position: relative;
    animation: PGMultiSpinAnimation 2s ease-in-out infinite;
    width: calc(100% - 1rem);
    height: calc(100% - 1rem);
    margin: 0.5rem;
    border: 2px solid $primary;
    border-left-color: transparent;
    border-right-color: transparent;
    border-radius: 100%;
}

.PGMultiSpin > div
{
    width: 5rem;
    height: 5rem;
}

@keyframes PGMultiSpinAnimation
{
    0% { 
        transform: rotate(0deg); 
        border-color: $primary; 
        border-left-color: transparent;
        border-right-color: transparent;
    }
    50% { 
        transform: rotate(90deg); 
        border-color: $secondary; 
        border-left-color: transparent;
        border-right-color: transparent;
    }
    100% { 
        transform: rotate(180deg); 
        border-color: $primary; 
        border-left-color: transparent;
        border-right-color: transparent;
    }
}

.PULSEALL *
{
    animation: PULSEALLAnimation 0.2s ease-out;
}

@keyframes PULSEALLAnimation
{
    0% { transform: scale(0.9, 0.9); opacity: 0; }
    100% { transform: scale(1, 1); opacity: 1; }
}

.PGCollapsibleContainer
{
    position: relative;
    padding: 10px 20px;
    background-color: rgba(0,0,0,0.075);
    margin: 5px 0;
}

.PGCollapsibleContainer-Toggle
{
    position: absolute;
    background-color: white;
    border-radius: 16px;
    cursor: pointer;
    z-index: 10;
    height: 16px;
    width: 16px;
    text-align: center;
    margin-left: -8px;
    margin-top: 6px;
    left: 0;
    top: 0;
    padding: 0;
}

.PGCollapsibleContainer-Toggle i
{
    font-size: 10px;
    line-height: 14px;
    vertical-align: top;
}

.PGCollapsibleContainer--HasShowWhenCollapsed > .PGCollapsibleContainer-Toggle
{
    margin-top: 16px;
}

.PGCollapsibleContainer.PGCollapsibleContainer--Collapsed 
{
    min-height: 28px;
}

.PGCollapsibleContainer.PGCollapsibleContainer--Collapsed::after
{
    content: "...";
    position: absolute;
    bottom: 3px;
    right: 0;
    box-sizing: content-box;
    left: 0;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}

.PGCollapsibleContainer--Collapsed > .row
{
    display: none;
}

.PGCollapsibleContainer--Collapsed > .row.PGCollapsibleContainer-ShowWhenCollapsed
{
    display: flex;
}

.PGCollapsibleContainer--Collapsed > .PGCollapsibleContainer-ShowWhenCollapsed .PGCollapsibleContainer-HideWhenCollapsed
{
    display: none;
}

.EFGMapsCluster
{
    background-color: $primary;
    border-radius: 100%;
    position: relative;
}

.EFGMapsCluster img
{
    display: none;
}

.EFGMapsCluster > div
{
    color: white !important;
    position: relative;
    z-index: 5;
}

.EFGMapsCluster::after,
.EFGMapsCluster > div::after,
.EFGMapsCluster > div::before
{
    content: "";
    background-color: $primary;
    position: absolute;
    width: 8px;
    height: 8px;
    bottom: -3px;
    z-index: 1;
    transform: translate(-4px, 0) rotate(45deg) skew(18deg, 18deg);
    left: 50%;
}

.EFGMapsCluster > div::before
{
    bottom: -1px;
    margin-left: -5px;
}

.EFGMapsCluster > div::after
{
    bottom: -1px;
    margin-left: 5px;
}

.EFGMapsCluster > div > span
{
    font-size: 0.75rem;
}

.EFGMapsCluster.bg-danger,
.EFGMapsCluster.bg-danger::after,
.EFGMapsCluster.bg-danger > div::after,
.EFGMapsCluster.bg-danger > div::before
{
    background-color: $danger;
}

.EFGMapsCluster.bg-success,
.EFGMapsCluster.bg-success::after,
.EFGMapsCluster.bg-success > div::after,
.EFGMapsCluster.bg-success > div::before
{
    background-color: $success;
}

.EFGMapsCluster.bg-warning,
.EFGMapsCluster.bg-warning::after,
.EFGMapsCluster.bg-warning > div::after,
.EFGMapsCluster.bg-warning > div::before
{
    background-color: $warning;
}

.EFGMapsCluster.bg-info,
.EFGMapsCluster.bg-info::after,
.EFGMapsCluster.bg-info > div::after,
.EFGMapsCluster.bg-info > div::before
{
    background-color: $info;
}

.CSSDEBUG *
{
    animation: CSSDEBUG-Animation 0.2s ease-out;
}

@keyframes CSSDEBUG-Animation
{
    0% { opacity: 0; transform: scale(0.5, 0.5); }
    100% { opacity: 1; transform: scale(1, 1); }
}

.opacity-00
{
    opacity: 0 !important;
}

.opacity-05
{
    opacity: 0.05 !important;
}

.opacity-10
{
    opacity: 0.1 !important;
}

.opacity-15
{
    opacity: 0.15 !important;
}

.opacity-20
{
    opacity: 0.2 !important;
}

.opacity-25
{
    opacity: 0.25 !important;
}

.opacity-30
{
    opacity: 0.3 !important;
}

.opacity-35
{
    opacity: 0.35 !important;
}

.opacity-40
{
    opacity: 0.4 !important;
}

.opacity-45
{
    opacity: 0.45 !important;
}

.opacity-50
{
    opacity: 0.5 !important;
}

.opacity-55
{
    opacity: 0.55 !important;
}

.opacity-60
{
    opacity: 0.6 !important;
}

.opacity-65
{
    opacity: 0.65 !important;
}

.opacity-70
{
    opacity: 0.7 !important;
}

.opacity-75
{
    opacity: 0.75 !important;
}

.opacity-80
{
    opacity: 0.8 !important;
}

.opacity-85
{
    opacity: 0.85 !important;
}

.opacity-90
{
    opacity: 0.9 !important;
}

.opacity-95
{
    opacity: 0.95 !important;
}

.opacity-100
{
    opacity: 1 !important;
}

.shadow-highlight
{
    box-shadow: 0 0 0 2px $primary;
}

.EFModal
{
    width: 100%;
    height: 100%;
    z-index: 500;
    position: absolute;
    top: 0;
    background-color: rgba(255,255,255,1);
    display: flex;
    flex-direction: column;
}

.EFModal-Body
{
    overflow: auto;
}

.EFModal-Header,
.EFModal-Footer
{
    display: flex;
    align-items: center;
}

.EFModal-Header
{
    justify-content: space-between;
    min-height: 50px;
}

.EFModal-Footer
{
    justify-content: flex-end;
}

.EFModal-Header:empty,
.EFModal-Footer:empty
{
    display: none;
}

.PGTimetableFormat-Day
{
    width: 1.5em;
    line-height: 1.5em;
    padding: 0;
    color: var(--bs-secondary);
    text-align: center;
    border-bottom: 2px solid var(--bs-light);
}

.PGTimetableFormat-Day.active
{
    color: var(--bs-primary);
    border-color: var(--bs-primary);
    text-shadow: 0 0 1px rgba(var(--bs-primary-rgb), 0.25) !important;
}

.PGModal-MaxHeight100 .modal-content {
    max-height: calc(100vh - 3.5rem);
    overflow: hidden;

    .modal-body {
        overflow: auto;
    }
}