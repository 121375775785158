body.EFStandalone {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #dee2e6;
    margin: 0;
}

@import '../../pg-lib/assets/fontawesome/css/all.css';
@import './variables.scss';
@import '../../../node_modules/bootstrap/scss/bootstrap';
@import '../../../styles/general.scss';
@import '../../../styles/animations.scss';

.EFLanding--KIOSK .gm-style a[target=_blank],
.EFLanding--IFRAMEKIOSK .gm-style a[target=_blank]
{
    pointer-events: none;
}