.pg-animation-shake 
{
    animation: PGKeyFrames-Shake 0.25s linear;
}

.pg-animation-spin 
{
    animation: PGKeyFrames-Spin 1s linear infinite;
}

.pg-animation-fadein 
{
    animation: PGKeyFrames-FadeIn 0.2s ease-out;
}

.pg-animation-fadeout
{
    animation: PGKeyFrames-FadeOut 0.2s ease-out;
}

.pg-animation-slideleft 
{
    animation: PGKeyFrames-SlideLeft 0.2s ease-out;
}

.pg-animation-slideright 
{
    animation: PGKeyFrames-SlideRight 0.2s ease-out;
}

.pg-animation-slideup 
{
    animation: PGKeyFrames-SlideUp 0.2s ease-out;
}

.pg-animation-slidedown 
{
    animation: PGKeyFrames-SlideDown 0.2s ease-out;
}

.pg-animation-grow 
{
    animation: PGKeyFrames-Grow 0.2s ease-out;
}

.pg-animation-fliph 
{
    animation: PGKeyFrames-FlipH 0.2s ease-out;
}

.pg-animation-flipv 
{
    animation: PGKeyFrames-FlipV 0.2s ease-out;
}

.pg-animation-reverse
{
    animation-direction: reverse;
}

.pg-animation-forwards
{
    animation-fill-mode: forwards;
}

.pg-animation-slow
{
    animation-duration: 0.4s !important;
}

.pg-animation-slower
{
    animation-duration: 0.6s !important;
}

.pg-animation-slowest
{
    animation-duration: 0.8s !important;
}

@keyframes PGKeyFrames-Spin
{
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
}

@keyframes PGKeyFrames-FadeIn
{
    0% { opacity: 0 }
    100% { opacity: 1 }
}

@keyframes PGKeyFrames-FadeOut
{
    0% { opacity: 1 }
    100% { opacity: 0 }
}

@keyframes PGKeyFrames-SlideLeft
{
    0% { transform: translate(50%, 0); opacity: 0; }
    100% { transform: translate(0, 0); opacity: 1; }
}

@keyframes PGKeyFrames-SlideRight
{
    0% { transform: translate(-50%, 0); opacity: 0; }
    100% { transform: translate(0, 0); opacity: 1; }
}

@keyframes PGKeyFrames-SlideUp
{
    0% { transform: translate(0, 50%); opacity: 0; }
    100% { transform: translate(0, 0); opacity: 1; }
}

@keyframes PGKeyFrames-SlideDown
{
    0% { transform: translate(0, -50%); opacity: 0; }
    100% { transform: translate(0, 0); opacity: 1; }
}

@keyframes PGKeyFrames-Grow
{
    0% { transform: scale(0.75, 0.75); opacity: 0; }
    100% { transform: scale(1, 1); opacity: 1; }
}

@keyframes PGKeyFrames-FlipH
{
    0% { transform: scale(1, 1); }
    100% { transform: scale(-1, 1); }
}

@keyframes PGKeyFrames-FlipV
{
    0% { transform: scale(1, 1); }
    100% { transform: scale(1, -1); }
}

@keyframes PGKeyFrames-EnterL
{
    0% { opacity: 0; transform: translate(-200px, 0); }
    100% { opacity: 1; transform: translate(0, 0); }
}

@keyframes PGKeyFrames-ExitL
{
    0% { opacity: 1; transform: translate(0, 0); }
    100% { opacity: 0; transform: translate(-200px, 0); }
}

@keyframes PGKeyFrames-EnterR
{
    0% { opacity: 0; transform: translate(200px, 0); }
    100% { opacity: 1; transform: translate(0, 0); }
}

@keyframes PGKeyFrames-ExitR
{
    0% { opacity: 1; transform: translate(0, 0); }
    100% { opacity: 0; transform: translate(200px, 0); }
}

@keyframes PGKeyFrames-EnterT
{
    0% { opacity: 0; transform: translate(0, -100px); }
    100% { opacity: 1; transform: translate(0, 0); }
}

@keyframes PGKeyFrames-ExitT
{
    0% { opacity: 1; transform: translate(0, 0); }
    100% { opacity: 0; transform: translate(0, -100px); }
}

@keyframes PGKeyFrames-EnterB
{
    0% { opacity: 0; transform: translate(0, 100px); }
    100% { opacity: 1; transform: translate(0, 0); }
}

@keyframes PGKeyFrames-ExitB
{
    0% { opacity: 1; transform: translate(0, 0); }
    100% { opacity: 0; transform: translate(0, 100px); }
}

@keyframes PGKeyFrames-Shake
{
    0% { transform: translate(-10px, 0); }
    20% { transform: translate(8px, 0); }
    40% { transform: translate(-6px, 0); }
    60% { transform: translate(4px, 0); }
    80% { transform: translate(-2px, 0); }
    100% { transform: translate(0, 0); }
}

.dropup, .dropright, .dropdown, .dropleft
{
    animation: PGKeyFrames-FadeIn 0.2s ease-out;
}