$yellow: #ffaa00;

$primary: #0d6efd;
$secondary: #6c757d;
$success: #198754;
$info: #0dcaf0;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #212529;

@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';

$color_border: shift-color($secondary, -70%);
$color_disabled_background: shift-color($secondary, -80%);
$color_disabled_text: shift-color($secondary, -50%);

$color_selectable_background: shift-color($secondary, -80%);
$color_selecting_background: shift-color($primary, -80%);
$color_selected_background: $primary;